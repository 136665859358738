.root {
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  margin: 0 var(--grid-gutter);
  position: absolute;
  right: 0;
  top: 0;
}

.header {
  flex-grow: 1;
}

.paymentProvider {
  /* border: thin solid green; */
  border: none;
  flex-grow: 99;
  height: 25em;
  margin: 0;
  min-height: 800px;
  padding: 0;
  width: 98%;
}

.noscript,
:global(.mf-noscript) .content {
  display: none;
}

:global(.mf-noscript) .noscript {
  animation: avoidFlash;
  animation-delay: 2s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  display: block;
  opacity: 0;
}

@keyframes avoidFlash {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
