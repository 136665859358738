.center {
  text-align: center;
}

.modalOverride section {
  min-height: unset;
}

.buttonGroup {
  display: flex;
  margin-top: var(--space-l);
}

.buttonGroup > :nth-child(1) {
  margin-right: var(--space-s);
}
