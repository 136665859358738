.container {
  display: flex;
  flex-direction: row;
}

.wrapper {
  display: grid;
  grid-auto-rows: minmax(150px, auto);
  grid-gap: 10px;
  grid-template-columns: 2fr 1fr 1fr;
}

.wrapper2 {
  display: grid;
  grid-auto-rows: minmax(150px, auto);
  grid-gap: 10px;
  grid-template-columns: 1fr 2fr 1fr;
}

.standardButton {
  @media (--up_to_M) {
    width: 10%;
  }

  @media (--M_to_L) {
    width: 15%;
  }

  @media (--XL_and_up) {
    width: 10%;
  }

  margin: 0.25rem;
}

.rowContainer {
  border-bottom: thin solid #e1e1e1;
  margin-top: 1.5rem;
}

.flexContainer {
  @media (--up_to_M) {
    flex-wrap: wrap;
  }

  @media (--M_to_XL) {
    justify-content: space-between;
    margin-bottom: var(--space-s);
    width: 100%;
  }

  @media (--XL_and_up) {
    margin: auto;
    margin-bottom: var(--space-s);
    width: 100%;
  }

  display: flex;
}

.flexItem {
  @media (--up_to_M) {
    margin: auto;
    width: 100%;
  }

  @media (--M_to_XL) {
    margin: auto;
    width: 70%;
  }

  @media (--XL_and_up) {
    margin: auto;
    width: 50%;
  }

  align-self: flex-end;
}

.flexItemButtonGroup {
  @media (--up_to_M) {
    flex-direction: column;
    justify-content: center;
    margin: var(--space-m) 0 var(--space-s) 0;
    width: 100%;
  }

  @media (--M_to_L) {
    flex-direction: column;
    justify-content: flex-end;
    margin: var(--space-m) 0 var(--space-xs) var(--space-m);
    width: 50%;
  }

  @media (--L_to_XL) {
    flex-direction: column;
    justify-content: flex-end;
    margin: var(--space-m) 0 var(--space-xs) var(--space-m);
    max-width: 300px;
    width: 50%;
  }

  @media (--XL_and_up) {
    align-items: center;
    justify-content: flex-end;
    width: 50%;
  }

  display: flex;
}

.editButton {
  @media (--up_to_M) {
    display: flex;
    justify-content: center;
    margin-bottom: var(--space-s);
    width: 100%;
  }

  @media (--M_to_XL) {
    display: flex;
    justify-content: center;
    margin-bottom: var(--space-s);
    width: 100%;
  }

  @media (--XL_and_up) {
    margin-right: var(--space-s);
  }
}

.deleteButton {
  @media (--up_to_M) {
    display: flex;
    justify-content: center;
    margin-bottom: var(--space-s);
    width: 100%;
  }

  @media (--M_to_XL) {
    display: flex;
    justify-content: center;
    margin-bottom: var(--space-s);
    width: 100%;
  }

  @media (--XL_and_up) {
    margin-right: var(--space-s);
  }
}

.setStandardButton {
  @media (--up_to_M) {
    margin-bottom: var(--space-xs);
    width: 100%;
  }

  @media (--M_to_XL) {
    width: 100%;
  }

  @media (--XL_and_up) {
    width: auto;
  }
}

.deliveryNewButtonContainer {
  @media (--up_to_L) {
    width: auto;
  }

  @media (--L_to_XL) {
    width: 50%;
  }

  @media (--XL_and_up) {
    width: 33%;
  }

  @media (--up_to_M) {
    margin-bottom: var(--space-xs);
    margin-top: var(--space-m);
  }

  @media (--M_and_up) {
    margin-bottom: var(--space-m);
    margin-top: var(--space-m);
  }
}

.center {
  text-align: center;
}

.modalOverride section {
  min-height: unset;
}

.buttonGroup {
  display: flex;
  margin-top: var(--space-l);
}

.buttonGroup > :nth-child(1) {
  margin-right: var(--space-s);
}
