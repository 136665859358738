.root {
  @media (--up_to_L) {
    margin-bottom: 4rem;
  }

  @media (--L_and_up) {
    margin-bottom: 8rem;
  }

  border-bottom: thin solid silver;
  display: table;
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.productlist {
  display: table;
  padding: 0;
  width: 100%;
}

.item {
  display: table-row;
}

.descriptionPt1 {
  @media (--up_to_L) {
    padding-top: 2rem;
    width: 100%;
  }

  @media (--L_and_up) {
    display: table-cell;
    padding: 3.5rem 1rem;
  }

  border-top: thin solid silver;
  margin: 0 0 var(--space-m) 0;
  vertical-align: top;
}

.descriptionPt2 {
  @media (--up_to_L) {
    padding: 0;
    width: 100%;
  }

  @media (--L_and_up) {
    border-top: thin solid silver;
    display: table-cell;
    padding: 3.5rem 1rem;
  }

  margin: 0 0 var(--space-m) 0;
  vertical-align: top;
}

.descriptionPt2 > div {
  margin-bottom: 0.5em;
}

.descriptionButtons {
  @media (--up_to_L) {
    display: flex;
  }
  @media (--L_and_up) {
    margin-top: var(--space-l);
  }
}

@media (--up_to_L) {
  .descriptionButtons button {
    padding: var(--space-s) 0;
  }
}

.aside {
  @media (--up_to_L) {
    flex-direction: row;
    width: 100%;
  }

  @media (--L_and_up) {
    border-top: thin solid silver;
    display: table-cell;
    margin: 0 0 var(--space-m) 0;
    padding-left: var(--grid-gutter);
    vertical-align: bottom;
    width: 25%;
  }

  padding-bottom: 3.5rem;
  white-space: nowrap;
}

.ageRelated .icon {
  display: inline-block;
  height: 2.8rem;
  margin: 0 0.5rem 0.5rem 0;
  vertical-align: middle;
  width: 2.8rem;
}

.root .ageRelated button {
  margin-right: var(--space-s);
  text-decoration: underline;
}

.ageRelatedDrawerClosed {
  display: none;
}

.ageRelatedDrawerOpen {
  display: block;
}
