.root,
.actions {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.li {
  @media (--up_to_M) {
    display: block;
  }

  @media (--M_and_up) {
    display: flex;
    justify-content: space-between;
  }

  border-bottom: thin solid var(--grey-50);
  margin: 0;
  margin-bottom: var(--space-xl);
  padding: 0;
  padding-bottom: var(--space-m);
}

.li:last-child:not(:first-child) {
  border-bottom: 0 none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.labelAndActions {
  @media (--up_to_M) {
    justify-content: flex-end;
  }

  align-items: flex-end;
  display: flex;
}

.addressHeading.addressHeading {
  margin-bottom: 0;
}

.input {
  flex: 1.5rem;
  margin-right: var(--space-s);
}

.actions {
  @media (--up_to_XL) {
    align-items: flex-end;
    flex-direction: column-reverse;
  }

  @media (--XL_and_up) {
    flex-direction: row-reverse;
  }

  display: flex;
}

.actions > li {
  @media (--up_to_XXL) {
    margin: 1rem 0 0 1.5rem;
  }

  @media (--XXL_and_up) {
    margin: 1rem 0 0 3rem;
  }
}
