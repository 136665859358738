.root {
  background-color: transparent;
  border: 0 none;
  cursor: pointer;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: var(--font-weight-copy-normal);
  line-height: 1;
  margin: 0;
  text-align: center;
  vertical-align: middle;
}

.alt {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute;
}

.content {
  align-items: center;
  display: flex;
  height: 100%;
}

.icon {
  color: var(--black-100);
}

.disabled {
  cursor: not-allowed;
}

.label {
  margin-left: 1rem;
}
