.footer {
  align-items: center;
  border-top: 1px solid var(--grey-50);
  display: flex;
  flex-direction: column;
  padding: var(--space-s) var(--space-s) var(--space-xs) var(--space-s);
}

.footer.disabled {
  @media (--M_and_up) {
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  background-color: var(--grey-50);
}

.form {
  background-color: var(--grey-35);
  border-radius: 3rem;
  cursor: text;
  display: flex;
  margin-bottom: var(--space-xs);
  padding: var(--space-xs);
  width: 100%;
}

.inputWrapper {
  display: inline-block;
  height: auto;
  margin: var(--space-s) var(--space-xs) var(--space-s) var(--space-s);
  position: relative;
  width: 100%;
}

.input {
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  color: var(--black-100);
  font-size: var(--font-size-input);
  height: auto;
  resize: none;
  scrollbar-color: var(--grey-50) transparent;
  text-overflow: ellipsis;
  vertical-align: top;
  width: 100%;
}

.input::placeholder {
  color: var(--grey-150);
  overflow: hidden;
  text-overflow: ellipsis;
}

.input:focus-visible {
  outline: 0;
}

.form:focus-within {
  border-color: var(--grey-75);
  outline: var(--focus-width) solid var(--main-100);
  outline-offset: 0;
}

.buttonWrapper {
  display: flex;
}

.sendButton {
  align-items: center;
  align-self: center;
  background-color: var(--main-100);
  border: 1px solid transparent;
  border-radius: 2.3rem;
  color: var(--white-100);
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-right: 0.1rem;
  user-select: none;
  width: 40px;
}

.sendButton:disabled {
  background-color: var(--grey-50);
  color: var(--grey-35);
  cursor: default;
}

.sendButton:focus-visible {
  border-color: var(--white-100);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: var(--focus-offset);
}

.maxLength {
  color: var(--grey-100);
  font-size: 1rem;
  position: absolute;
  right: 0;
  top: -12px;
}

.inputLengthExceeded {
  color: var(--attention-100);
}

.tooltipContent {
  background-color: var(--white-100);
  border-radius: 4px;
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  max-height: var(--radix-tooltip-content-available-height);
  padding: 10px 15px;
  pointer-events: none;
  user-select: none;
  width: var(--radix-tooltip-trigger-width);
}

.tooltipArrow {
  fill: var(--white-100);
}

.disclaimer {
  text-align: center;
}
