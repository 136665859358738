.root {
  @media (--up_to_L) {
    margin: var(--space-m) 0 var(--space-m) 0;
  }

  @media (--L_and_up) {
    margin: var(--space-m) 0 var(--space-xxl) 0;
  }
}

.crumblist {
  display: inline-block;
  line-height: calc(2.2rem + var(--focus-width) * 2);
  list-style: none;
  margin: 0;
  max-width: 100%;
  padding: 0;
  vertical-align: baseline;
}

.crumb {
  display: inline-block;
  height: calc(2.2rem + var(--focus-width) * 2);
  line-height: calc(2.2rem + var(--focus-width) * 2);
  margin-right: var(--space-xs);
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.crumb.optional {
  @media (--up_to_L) {
    display: none;
  }
}

.crumb.back {
  margin-right: var(--space-s);
  padding-right: var(--space-s);
  position: relative;
}

.crumb.back::after {
  border-right: 1px solid var(--color-divider-prio1);
  content: "";
  display: block;
  height: 1.2rem;
  position: absolute;
  right: 0;
  top: 0.7rem;
  width: 0;
}

.crumb .lastArrow {
  margin: 0 0 0 var(--space-xs);
}

.crumbTextContainer {
  align-items: baseline;
  display: flex;
  margin: var(--focus-width);
}

.iconContainer {
  margin-right: var(--space-xs);
  transform: translateY(1px);
}

.crumb.back + .crumb .iconContainer:first-child {
  display: none;
}

.crumb .endpoint {
  display: inline;
  font-weight: var(--font-weight-copy-semibold);
  line-height: calc(2.5rem + var(--focus-width) * 2);
}

.productCount {
  padding-left: 0.8rem;
}

.skeleton {
  background-color: var(--grey-50);
  color: transparent !important;
  display: inline-block;
  height: auto;
  line-height: calc(1em + var(--focus-width) * 2);
  margin: 0.6rem 0 0;
  overflow: hidden;
  vertical-align: sub;
}

.skeleton.endpoint {
  vertical-align: middle;
}
