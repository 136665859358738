.shippingStatus {
  display: flex;
  flex-flow: column;
  line-height: var(--space-xl);
  margin-bottom: 1rem;
}

.shippingMessage {
  margin: 0.5rem 0;
}

.deliveryContainer {
  display: flex;
  flex-flow: row;
}

.deliveryContainer > * {
  margin-right: 1rem;
}
