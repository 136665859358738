.root {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.main {
  @media (--up_to_M) {
    margin-bottom: var(--space-xxl);
    padding: 0 var(--grid-gutter);
    width: var(--grid-12-12);
  }

  @media (--M_to_XL) {
    margin-bottom: var(--space-xxl);
    padding: 0 var(--grid-gutter);
    width: var(--grid-10-12);
  }

  @media (--XL_and_up) {
    padding: 0 var(--grid-gutter) 0 calc(var(--grid-1-12) + var(--grid-gutter));
    width: var(--grid-6-12);
  }
}

.main.common {
  @media (--up_to_M) {
    order: 3;
  }

  @media (--M_to_XL) {
    order: 3;
  }
}

.main.variant {
  @media (--up_to_M) {
    order: 1;
  }

  @media (--M_to_XL) {
    order: 1;
  }
}

.side {
  @media (--up_to_M) {
    order: 2;
    width: var(--grid-12-12);
  }

  @media (--M_to_XL) {
    order: 2;
    width: var(--grid-10-12);
  }

  @media (--XL_and_up) {
    padding: 0 var(--grid-1-12);
    width: var(--grid-6-12);
  }
}

.stack {
  padding: 0 var(--grid-gutter);
}

.element {
  @if ($manufactum) {
    padding-bottom: var(--space-xl);
  }

  @if $magazin {
    padding-bottom: var(--space-xxl);
  }
}

@if ($manufactum) {
  * + .element {
    border-top: 1px solid var(--color-divider-prio2);
    padding-top: var(--space-l);
  }

  @media (--M_to_XL) {
    .main.variant > *:first-child {
      border-bottom: 1px solid var(--color-divider-prio2);
      padding-bottom: var(--space-l);
    }
  }

  @media (--up_to_XL) {
    .main.common > *:first-child {
      border-top: 1px solid var(--color-divider-prio2);
      padding-top: var(--space-l);
    }
  }
}
