@media (--up_to_XL) {
  .container {
    display: block;
  }
}

@media (--XL_and_up) {
  .container {
    display: flex;
  }
}
