.root {
  @media (--up_to_L) {
    display: none;
  }
  @media (--L_and_up) {
    background: none;
    border: none;
    color: var(--white-100);
    cursor: pointer;
    display: block;
    outline: none;
    padding: var(--space-s);
    position: absolute;
    top: calc(50% - 30px);
    z-index: 1;
  }
}

.prev {
  left: var(--focus-width);
}

.next {
  right: var(--focus-width);
}

.root:focus:not(:hover):not(:focus-visible) {
  animation: focus 300ms ease-in;
}

.root:focus-visible {
  background-color: var(--focus-background);
  border-radius: var(--focus-border-radius);
  box-shadow: 0 0 0 calc(var(--focus-width)) var(--focus-color-inverted);
  color: var(--focus-text-color);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: calc(-1 * var(--focus-width));
}

@keyframes focus {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.iconWrapper svg {
  filter: drop-shadow(0 0 3px #000000);
}
