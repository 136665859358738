.main {
  margin: 0 var(--grid-outer-space) var(--space-xxxxl) var(--grid-outer-space);
  min-height: 40rem;
}

:global(.render-app) .main {
  margin-top: var(--grid-outer-space);
}

.headline {
  margin: 0 var(--grid-gutter);
  text-align: center;
}

.topBar {
  @media (--up_to_L) {
    margin-bottom: var(--space-m);
  }

  @media (--L_and_up) {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin: var(--space-s) 0;
    text-align: right;
  }
}

.bottomBar {
  margin: var(--space-s) 0 var(--space-m);
  text-align: center;
}

.topPager {
  @media (--up_to_L) {
    display: none;
  }

  @media (--L_and_up) {
    display: flex;
    margin: 0 0 var(--space-m) var(--space-xxl);
  }
}

.sortOrder {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--space-m);
}

.filterButton {
  @media (--up_to_M) {
    display: block;
    flex: 4;
    flex-basis: var(--grid-4-12);
    padding-left: var(--space-s);
  }

  @media (--M_to_L) {
    display: block;
    flex: 3;
    flex-basis: var(--grid-3-12);
    padding-left: var(--space-m);
  }

  @media (--L_and_up) {
    display: none;
  }
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
}

.facetBar {
  @media (--up_to_L) {
    display: inline-block;
    width: 100%;
  }

  @media (--L_to_XXL) {
    display: flex;
    flex-basis: var(--grid-3-12);
    flex-direction: column;
    margin: var(--space-s) 0;
    order: 1;
    width: var(--grid-3-12);
  }

  @media (--XXL_and_up) {
    display: flex;
    flex-basis: var(--grid-2-12);
    flex-direction: column;
    margin: var(--space-s) 0;
    order: 1;
    width: var(--grid-2-12);
  }

  -ms-flex: 0 0 auto;
  padding: 0 var(--grid-gutter);
}

.productsWrapper {
  @media (--up_to_L) {
    width: var(--grid-12-12);
  }

  @media (--L_to_XXL) {
    flex-basis: var(--grid-9-12);
    order: 2;
    width: var(--grid-9-12);
  }

  @media (--XXL_and_up) {
    flex-basis: var(--grid-10-12);
    order: 2;
    width: var(--grid-10-12);
  }

  -ms-flex: 0 0 auto;
}

.productsWrapperFull {
  width: var(--grid-12-12);
}
