.center {
  text-align: center;
}

.flex {
  display: flex;
  gap: 3rem;
  justify-content: center;
}

.left {
  max-width: 40%;
}

.appStoreImages {
  @media (--up_to_XXL) {
    flex-direction: column;
  }

  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 var(--space-l);
}

.appStoreImages a:nth-child(1) {
  @media (--up_to_XXL) {
    margin-bottom: var(--space-l);
  }
  @media (--XXL_and_up) {
    margin-right: var(--space-l);
  }
}

.desktop {
  @media (--up_to_M) {
    display: none;
  }
}

.mobile {
  @media (--up_to_M) {
    display: flex;
  }
  @media (--M_and_up) {
    display: none;
  }
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.time {
  color: var(--main-100);
  display: flex;
  font-size: 6rem;
  font-variant-numeric: tabular-nums;
  font-weight: 800;
  justify-content: center;
  margin-top: -2.4rem;
  text-shadow:
    -3px -3px 0 #ffffff,
    3px -3px 0 #ffffff,
    -3px 3px 0 #ffffff,
    3px 3px 0 #ffffff;
}

.barcodeImage {
  margin: 0 calc(-1 * (var(--grid-outer-space) + var(--grid-1-12)));
}

.desktopTextDetails {
  margin-top: var(--space-m);
}
