.filters {
  @media (--up_to_L) {
    box-shadow: -20px 0 7px -20px rgba(0, 0, 0, 0.1) inset;
    width: var(--grid-9-12);
  }

  max-height: 150px;
  overflow-y: auto;
  width: 100%;

  /* -webkit-overflow-scrolling: touch; */

  /* -ms-overflow-style: -ms-autohiding-scrollbar; */
}

.checkbox {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.activeFilter .checkbox:focus + label,
.checkbox:focus + label {
  outline: var(--focus-width) solid var(--focus-color);
}

.ul {
  border-spacing: 2px 0.5rem;
  display: table;
  list-style: none;
  margin: 0;
  padding: 0 0 6px;
}

.labelElementOld {
  border: 1px solid #c4c4c4;
  height: 40px;
  margin: 3px;
  text-align: center;
  width: 53px;
}

.label.disabled {
  opacity: 0.5;
}

.filter {
  border: 1px solid #c4c4c4;
  display: inline-block;
  height: 46px;
  margin: 0.5rem 0.2rem;
  text-align: center;
  width: 62px;
}

.filter label {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.filter label:not(.disabled) {
  cursor: pointer;
}

.filter label:not(.disabled):hover small {
  color: var(--main-100);
  text-decoration: underline;
}

.activeFilter small {
  color: var(--main-100);
  text-decoration: underline;
}

.filter.activeFilter label:not(.disabled):hover small {
  color: var(--black-100);
}

.filter.activeFilter {
  border: 2px solid #222222;
}
