.root {
  align-items: center;
  border-bottom: 1px solid var(--color-divider-prio2);
  color: inherit;
  display: flex;
  font-size: inherit;
  margin-bottom: var(--space-m);
  padding-bottom: var(--space-m);
  text-decoration: none;
}

.textGap {
  gap: var(--space-m);
}

.button {
  background: none;
  border: 0;
  height: 3rem;
  margin-left: var(--space-m);
  padding: 0;
}

.button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.button:hover {
  cursor: pointer;
}

.overlay {
  margin: 0 var(--space-l);
  min-height: 400px;
}

.overlayImage {
  max-width: 100%;
  width: 300px;
}

.overlay strong {
  font-weight: bold;
  line-height: 2.5rem;
}
