.root {
  @media (--up_to_M) {
    margin-bottom: var(--space-l);
  }

  @media (--M_and_up) {
    margin-bottom: var(--space-xxl);
  }

  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.column {
  margin-bottom: var(--space-xxl);
  padding: 0 var(--grid-gutter);
}

.fullWidth .image {
  @media (--up_to_M) {
    width: 100%;
  }

  @media (--M_to_XL) {
    width: calc((100% / 10) * 4);
  }

  @media (--XL_and_up) {
    width: calc((100% / 8) * 3);
  }

  display: block;
}

.indented .image {
  @media (--up_to_M) {
    width: 100%;
  }

  @media (--M_to_L) {
    width: calc((100% / 10) * 4);
  }

  @media (--L_to_XL) {
    width: calc((100% / 9) * 3);
  }

  @media (--XL_to_XXL) {
    width: calc((100% / 7) * 3);
  }

  @media (--XXL_and_up) {
    width: calc((100% / 8) * 3);
  }

  display: block;
}

.text {
  margin-top: -0.3rem;
}

.fullWidth .text {
  @media (--up_to_M) {
    width: 100%;
  }

  @media (--M_to_XL) {
    width: calc((100% / 10) * 6);
  }

  @media (--XL_and_up) {
    width: calc((100% / 8) * 5);
  }
}

.indented .text {
  @media (--up_to_M) {
    width: 100%;
  }

  @media (--M_to_L) {
    width: calc((100% / 10) * 6);
  }

  @media (--L_to_XL) {
    width: calc((100% / 9) * 6);
  }

  @media (--XL_to_XXL) {
    width: calc((100% / 7) * 4);
  }

  @media (--XXL_and_up) {
    width: calc((100% / 8) * 5);
  }
}

.title {
  margin-bottom: var(--space-s);
}
