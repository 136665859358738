.root {
  display: block;
  font-size: var(--font-size-copy-normal);
  padding: var(--space-l) 0;
}

.root button {
  display: none;
}

:global(.mf-noscript) .root button {
  display: inline-block;
}

.root tr {
  display: block;
}

.catalogSelectionList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.catalogsData,
.catalogsHeader {
  display: block;
  text-align: left;
  width: 100%;
}

.catalogsHeader {
  background-color: var(--grey-50);
  color: var(--darkGrey-105);
  font-weight: normal;
  line-height: var(--line-height-copy-normal);
  margin-bottom: var(--space-l);
  padding: var(--space-s) var(--space-m);
}
