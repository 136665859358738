.input.hasSuggestions {
  position: relative;
  z-index: 100;
}

.suggestionsContainer {
  @if $magazin {
    margin: 0 auto;
    max-width: var(--app-width);
    right: 0;
  }

  @media (--up_to_L) {
    padding: 0.3rem var(--grid-outer-space);
  }

  @media (--L_and_up) {
    padding: var(--space-s) var(--grid-outer-space);
  }

  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: 100;
}
.suggestionsContainer.hidden {
  display: none;
}

.overlay {
  @media (--up_to_L) {
    margin: 0 var(--grid-gutter);
    padding: var(--space-l) var(--grid-outer-space) var(--space-xs);
  }

  @media (--L_to_XL) {
    margin: 0 auto;
    padding: var(--space-l) var(--grid-outer-space);
    width: calc(var(--grid-10-12) + (2 * var(--grid-gutter)));
  }

  @media (--XL_to_XXL) {
    margin: 0 auto;
    padding: var(--space-l) var(--grid-outer-space);
    width: calc(var(--grid-8-12) + (2 * var(--grid-gutter)));
  }

  @media (--XXL_and_up) {
    margin: 0 auto;
    padding: var(--space-l) var(--grid-outer-space);
    width: calc(var(--grid-6-12) - (2 * var(--grid-gutter)));
  }

  background-color: var(--white-100);
  border-radius: 0.3rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-wrap: wrap;
  pointer-events: auto;
}

.overlay:empty {
  display: none;
}

.sectionContainer {
  padding: 0 var(--grid-gutter);
}

.headline {
  @media (--up_to_L) {
    display: none;
  }

  margin-bottom: var(--space-l);
}

.words {
  @media (--up_to_L) {
    width: 100%;
  }

  @media (--L_to_XL) {
    @if $manufactum {
      flex-basis: calc(38% - var(--grid-gutter));
      max-width: calc(38% - var(--grid-gutter));
    }

    @if $magazin {
      flex-basis: calc(34.5% - var(--grid-gutter));
      max-width: calc(34.5% - var(--grid-gutter));
    }
  }

  @media (--XL_to_XXL) {
    flex-basis: calc(26.3% + var(--grid-gutter));
    max-width: calc(26.3% + var(--grid-gutter));
  }

  @media (--XXL_and_up) {
    flex-basis: 32.5%;
    max-width: 32.5%;
  }

  position: relative;
}

.words .sectionInner {
  @media (--L_and_up) {
    border-right: 1px solid var(--color-divider-prio2);
    height: 100%;
  }
}

.products {
  @media (--up_to_L) {
    display: none;
  }

  @media (--L_to_XL) {
    @if $manufactum {
      flex-basis: calc(62% + var(--grid-gutter));
      max-width: calc(62% + var(--grid-gutter));
    }

    @if $magazin {
      flex-basis: calc(65.5% + var(--grid-gutter));
      max-width: calc(65.5% + var(--grid-gutter));
    }
  }

  @media (--XL_to_XXL) {
    flex-basis: calc(73.7% - var(--grid-gutter));
    max-width: calc(73.7% - var(--grid-gutter));
  }

  @media (--XXL_and_up) {
    flex-basis: 67.5%;
    max-width: 67.5%;
  }

  flex: 1;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.suggestionList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.suggestion {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-l);
  overflow: hidden;
  padding-right: var(--grid-gutter);
  text-overflow: ellipsis;
}

.suggestion.focused.suggestion.focused {
  text-decoration: underline;
}

.icon {
  @media (--up_to_L) {
    display: none;
  }

  color: var(--grey-60);
}

.bottomLink {
  @media (--up_to_L) {
    display: none;
  }

  flex: 1;
  padding: 0 var(--grid-gutter);
  text-align: right;
}

.products + .bottomLink {
  flex-basis: 100%;
  width: 100%;
}

.closepane {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
}
