.root {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

.closeButton {
  align-items: center;
  background-color: var(--grey-35);
  border: 1px solid transparent;
  border-radius: 50%;
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.4);
  color: var(--black-100);
  cursor: pointer;
  display: flex;
  height: 28px;
  justify-content: center;
  margin-bottom: 0.5rem;
  padding: 0;
  transition: box-shadow 0.2s;
  user-select: none;
  width: 28px;
}

.closeButton:hover {
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
  transition: box-shadow 0.1s;
}

.closeButton:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
}

.mainButton {
  background-color: var(--main-100);
  border: 1px solid transparent;
  border-radius: 50%;
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.4);
  color: var(--darkGrey-105);
  cursor: pointer;
  display: inline-block;
  height: 52px;
  margin: 0;
  padding: 0 var(--space-m) 0.2rem;
  transition: box-shadow 0.2s;
  user-select: none;
  width: 52px;
}

.mainButton:hover {
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
  transition: box-shadow 0.1s;
}

.mainButton:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
}

.closeButton:focus-visible,
.mainButton:focus-visible {
  background-color: var(--focus-background);
  color: var(--focus-text-color);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: calc(-1 * var(--focus-width));
}

.content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.icon {
  color: var(--white-100);
  text-align: center;
}

.tooltipWrapper {
  cursor: pointer;
}

.tooltipContent {
  background-color: var(--white-100);
  border-radius: 4px;
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  padding: 10px 15px;
  user-select: none;
}

.tooltipArrow {
  fill: var(--white-100);
}
