.root {
  position: relative;
}

.embla {
  overflow: hidden;
}

.slides {
  display: flex;
}

.slide {
  flex: 0 0 100%;
  min-width: 0;
}

.mosaics {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.slideContent a:focus-visible .ratioImage div {
  border-radius: 0;
  box-shadow: inset 0 0 0 calc(2 * var(--focus-width) + var(--focus-offset))
    var(--focus-color-inverted);
  outline: calc(2 * var(--focus-width)) solid var(--focus-color);
  outline-offset: calc(-1 * var(--focus-width) - var(--focus-offset));
  position: relative;
  z-index: 1;
}

.skip {
  font-size: var(--font-size-copy-normal);
  left: -999999px;
  position: absolute;
}

.skip:focus-visible {
  background-color: var(--focus-background);
  border-color: transparent;
  border-radius: var(--focus-border-radius);
  box-shadow: 0 0 0 calc(var(--focus-width)) var(--focus-color-inverted);
  color: var(--focus-text-color);
  left: 0px;
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: calc(-1 * var(--focus-width));
  padding: var(--space-m);
  width: 100%;
  z-index: 1;
}

.navigation {
  align-items: center;
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  margin-top: var(--space-s);
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 1;
}

.navigation li {
  display: flex;
}

.navigation * {
  -webkit-transition: opacity 0.25s ease-in;
  -moz-transition: opacity 0.25s ease-in;
  -ms-transition: opacity 0.25s ease-in;
  -o-transition: opacity 0.25s ease-in;
  transition: opacity 0.25s ease-in;
}

.dot {
  background: transparent;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 2.4rem;
  margin: 0 2px;
  width: 2.4rem;
}

.dot > .icon {
  background: var(--grey-150);
  border-radius: 50%;
  display: flex;
  height: 1.2rem;
  opacity: 0.2;
  width: 1.2rem;
}

.dot.selected > .icon,
.dot:hover > .icon {
  background: var(--main-100);
  opacity: 1;
}

.dot:focus-visible {
  border-color: var(--white-100);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: var(--focus-offset);
}

.screenreaderOnly {
  border: 0;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.autoplay {
  align-items: center;
  background: none;
  border: none;
  color: var(--black-100);
  cursor: pointer;
  display: flex;
  height: 2.4rem;
  justify-content: center;
  margin: 0 2px;
  opacity: 0.5;
  padding: 0;
  width: 2.4rem;
}

.autoplay:focus-visible {
  border-radius: var(--focus-border-radius);
  opacity: 1;
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: 2px;
}

.autoplay:hover {
  color: var(--main-100);
  opacity: 1;
}
