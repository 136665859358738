.root {
  align-items: center;
  display: flex;
  margin: 0 auto;
  max-width: 720px;
}

.overlay {
  @media (--up_to_M) {
    min-width: 100%;
  }

  @media (--M_and_up) {
    min-width: 350px;
  }

  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.5);
  margin: 0 auto;
}

:global(.mf-ios) .overlay,
:global(.mf-ios) .header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.header {
  align-items: center;
  background-color: var(--main-100);
  display: flex;
  justify-content: flex-end;
  padding: var(--space-m);
}

.headline.headline.headline {
  @if $manufactum {
    padding-left: 1.8rem;
  }

  @if $magazin {
    border: none;
    display: flex;
    padding: 0;
  }

  border: none;
  flex: 1;
  margin: 0 0 var(--space-s);
}

.icon {
  display: inline-block;
  margin-right: 1.2rem;
  vertical-align: bottom;
}

.closeButton {
  background: none;
  border: 0 none;
  color: var(--white-100);
  cursor: pointer;
  margin: -1.3rem;
  padding: 1.3rem;
}

.backButton {
  background: none;
  border: 0 none;
  color: var(--white-100);
  cursor: pointer;
  margin: -1.3rem 0 -1.3rem -1.5rem;
  padding: 1.3rem;
}

.closeButton:focus-visible,
.backButton:focus-visible {
  background-color: var(--focus-background);
  color: var(--focus-text-color);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: calc(-1 * var(--focus-width));
}

.title {
  color: var(--white-100);
  font-family: var(--font-family-copy);
  font-size: 1.8rem;
  line-height: var(--line-height-headline-prio2);
  width: 100%;
}

.content {
  background-color: var(--white-100);
  max-height: 50.5vh;
  overflow-y: auto;
}

.subheader {
  align-items: center;
  color: var(--black-100);
  display: flex;
  font-family: var(--font-family-copy);
  font-size: 1.8rem;
  height: 48px;
  line-height: var(--line-height-headline-prio2);
  padding: 0 var(--space-m);
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list li {
  border-bottom: 1px solid var(--grey-60);
}

.list li:last-child {
  border: none;
}

.list li:first-child {
  border-top: 1px solid var(--grey-60);
}

.listItemButton {
  align-items: center;
  color: var(--black-100);
  display: flex;
  font-family: var(--font-family-copy);
  height: 48px;
  padding: 0 var(--space-m);
  text-decoration: none;
}

.listItemButton:focus-visible {
  background-color: var(--focus-background);
  border-radius: 0;
  color: var(--focus-text-color);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: calc(-1 * var(--focus-width));
}
