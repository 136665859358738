.container {
  padding: 0 10px;
}
.content {
  margin-bottom: var(--space-s);
  text-align: center;
}
.recipientContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: var(--space-l) 0;
}
.recipientLabel {
  margin-right: var(--space-m);
}
.recipientEmail {
  word-break: break-all;
}
