.a:link,
.a:visited {
  color: inherit;
  display: inline-block;
  text-decoration: none;
}

.a:hover .link,
.a:active .link,
.a:focus .link {
  text-decoration: underline;
}

.gutter {
  margin: 0 var(--grid-gutter);
}

.h2 {
  padding-top: var(--space-l);
}

.packstationDescription {
  text-align: center;
}
