.root {
  @media (--up_to_L) {
    flex: 7;
    flex-basis: var(--grid-8-12);
  }

  @media (--L_and_up) {
    width: 35rem;
  }
}
