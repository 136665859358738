.root {
  margin-bottom: var(--margin-headline-prio1);
}

.smallScreen {
  @media (--L_and_up) {
    display: none;
  }

  padding: 0 var(--grid-gutter);
}

.largeScreen {
  @media (--up_to_L) {
    display: none;
  }
}
