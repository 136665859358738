.pictograms {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-s);
  margin-bottom: var(--space-s);
}

.pictograms > img {
  height: 64px;
  width: 64px;
}
