.address {
  margin-right: 3rem;
  min-width: 20rem;
}

.center {
  text-align: center;
}

.editButtonWrapper {
  @media (--up_to_L) {
    width: auto;
  }

  @media (--L_to_XL) {
    width: 50%;
  }

  @media (--XL_and_up) {
    width: 33%;
  }

  margin-bottom: 3rem;
  margin-top: 3rem;
}
