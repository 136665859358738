.root {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--space-s);
}
.item {
  display: flex;
  margin-bottom: var(--space-xs);
}
.itemLabel {
  min-width: 25rem;
}
.list {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-decoration: underline;
}
.list li {
  padding: 0 var(--space-s);
  white-space: nowrap;
}
.list li > * {
  margin-right: var(--space-l);
}
.returnlist {
  flex-direction: column;
}
.returnlistItem {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
@media (--S_to_M) {
  .item {
    flex-direction: column;
  }
  .list {
    flex-wrap: wrap;
    margin-left: calc(-1 * var(--space-l));
    width: calc(100% + var(--space-l));
  }
  .list li {
    margin-left: var(--space-l);
    padding: 0;
  }
}
.hr {
  border: 0 none;
  border-bottom-style: solid;
  border-bottom-width: thin;
  border-color: #000000;
  margin: var(--space-xl) 0 var(--space-s) 0;
  padding: 0;
  width: 15rem;
}
.infoText {
  margin: var(--space-m) 0 var(--space-s) 0;
}
