.root {
  position: relative;
}

.box {
  align-items: center;
  display: flex;
  max-width: 100%;
  width: 100%;
}

.imagebox {
  @media (--L_to_XL) {
    flex-basis: calc(24.7% - var(--grid-gutter));
    max-width: calc(24.7% - var(--grid-gutter));
  }

  @media (--XL_to_XXL) {
    flex-basis: calc(20% + var(--grid-gutter));
    max-width: calc(20% + var(--grid-gutter));
  }

  @media (--XXL_and_up) {
    flex-basis: calc(25% + var(--grid-gutter));
    max-width: calc(25% + var(--grid-gutter));
  }

  flex-shrink: 0;
  margin-top: 1rem;
  padding-right: calc(2 * var(--grid-gutter));
}

.textbox {
  flex: 1;
  max-width: 75%;
  position: relative;
}

.link {
  color: black;
  cursor: pointer;
  display: block;
  height: 100%;
  max-width: 100%;
  text-decoration: none;
  width: 100%;
}

.selected .link,
.link:focus {
  text-decoration: underline;
}

.selected .link::after,
.link:focus::after {
  background-color: rgba(150, 150, 150, 0.05);
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
}

.link:focus:active {
  text-decoration: none;
}

.link:focus:active::after {
  display: none;
}
