.description {
  margin-bottom: var(--space-l);
}

.variationDescription {
  margin-bottom: var(--space-l);
}

.ratings {
  display: flex;
  flex-wrap: wrap;
}

.ratings .count {
  margin: 0 var(--space-m) 0 var(--space-xs);
}

.ingredientsText {
  color: var(--color-copy-normal);
  font-size: var(--font-size-copy-small);
  font-weight: var(--font-weight-copy-small);
  line-height: var(--line-height-copy-small);
}
