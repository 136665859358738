.a11yHelper {
  left: -999em;
  position: absolute;
}

@media (--up_to_L) {
  .root,
  .root tbody {
    display: block;
  }

  .root thead {
    display: none;
  }
}

@media (--L_and_up) {
  .root {
    border-collapse: collapse;
    width: 100%;
  }

  .root tr > * {
    @media (--up_to_L) {
      display: block;
    }

    padding: var(--space-m);
  }

  .root th {
    text-align: left;
  }

  .root th.colVariant {
    text-align: right;
  }

  .root tr > *:last-child {
    text-align: right;
  }

  .colArticleNumber {
    width: var(--grid-2-12);
  }

  .colArticleDescription {
    width: var(--grid-6-12);
  }

  .colQuantity,
  .colVariant {
    width: var(--grid-2-12);
  }
}
