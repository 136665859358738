.headline {
  padding: 0 calc(var(--grid-gutter) + var(--grid-outer-space));
}

.mainImage {
  @media (--M_to_L) {
    padding: 0 var(--grid-1-12);
  }

  @media (--L_to_XL) {
    padding: 0 calc(var(--grid-1-12) / 2) 0 0;
  }

  @media (--XL_to_XXL) {
    padding: 0;
  }

  @media (--XXL_and_up) {
    padding: 0 calc((var(--grid-1-12) / 2) + var(--grid-outer-space)) 0 0;
  }

  margin: 0 var(--grid-gutter);
}

.products {
  padding: 0 var(--grid-gutter);
}
