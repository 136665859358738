.main {
  @media (--L_and_up) {
    display: flex;
  }

  margin: 0 var(--grid-outer-space) var(--space-xxxxl) var(--grid-outer-space);
}

:global(.render-app) .main {
  margin-top: var(--grid-outer-space);
}

.left {
  @media (--up_to_L) {
    margin-bottom: var(--space-m);
  }

  @media (--L_to_XXL) {
    flex-basis: var(--grid-3-12);
    margin-top: 1.4rem;
  }

  @media (--XXL_and_up) {
    flex-basis: var(--grid-2-12);
    margin-top: 1.4rem;
  }
}

.middle {
  @media (--L_to_XXL) {
    flex-basis: var(--grid-9-12);
    margin-top: 0.2rem;
    max-width: var(--grid-9-12);
  }

  @media (--XXL_and_up) {
    flex-basis: var(--grid-10-12);
    margin-top: 0.2rem;
    max-width: var(--grid-10-12);
  }
}

.fullWidth {
  flex-basis: var(--grid-12-12);
  max-width: 100%;
}

.infoContentTop {
  @media (--up_to_L) {
    display: block;
  }

  @media (--L_and_up) {
    display: none;
  }
}

.infoContent {
  @media (--up_to_L) {
    display: none;
  }

  @media (--L_and_up) {
    display: block;
  }
}

.topBar {
  @media (--up_to_L) {
    margin-bottom: var(--space-m);
  }

  @media (--L_and_up) {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin: 0 0 var(--space-s) 0;
    text-align: right;
  }
}

.mainHeadingMobile {
  margin-top: 2rem;
}

.kuratorTopMobile {
  @media (--up_to_L) {
    margin-top: var(--space-l);
  }
}

.products.underlined {
  border-bottom: 1px solid #c8c8c8;
  margin-bottom: var(--space-xxl);
  padding-bottom: var(--space-s);
}

.bottomBar {
  margin: var(--space-s) 0 var(--space-m);
  text-align: center;
}

.bottomBar.underlined {
  border-bottom: 1px solid #c8c8c8;
  margin-bottom: var(--space-xxl);
  padding-bottom: var(--space-xxl);
}

.topPager {
  @media (--up_to_L) {
    display: none;
  }

  @media (--L_and_up) {
    display: flex;
    /* margin: 0 0 var(--space-m) var(--space-xxl); */
    margin-left: 2rem;
  }
}

.sortOrder {
  display: flex;
  flex-wrap: wrap;
}

.filterButton {
  @media (--up_to_M) {
    display: block;
    flex: 4;
    flex-basis: var(--grid-4-12);
    padding-left: var(--space-s);
  }

  @media (--M_to_L) {
    display: block;
    flex: 3;
    flex-basis: var(--grid-3-12);
    padding-left: var(--space-m);
  }

  @media (--L_and_up) {
    display: none;
  }
}

.filterButton .hasBubble svg {
  display: block;
}
