.bulletPointList {
  @media (--up_to_L) {
    padding: 0 0 0 1.8rem;
  }
  @media (--L_and_up) {
    padding: 0 2rem;
  }
}

.sidebar {
  @media (--up_to_L) {
    display: none;
  }
  @media (--L_and_up) {
    display: block;
  }
}

.variantInfo {
  @media (--up_to_L) {
    display: block;
  }
  @media (--L_and_up) {
    display: none;
  }
}
