.root {
  display: flex;
  flex-direction: column;
}

.pdfHref {
  color: var(--grey-150);
  display: flex;
  padding: var(--space-s) 0;
}

.icon {
  margin-right: var(--space-s);
}
