.root {
  background: var(--grey-50);
  border: 0 none;
  margin: 15px 0;
  padding: 1.5rem 1.5rem 1rem;
}

.compact {
  width: 100%;
}

.fluid {
  @media (--up_to_L) {
    display: block;
  }

  @media (--L_and_up) {
    display: table;
  }

  width: 100%;
}
