.items {
  @media (--up_to_M) {
    width: var(--grid-12-12);
  }

  @media (--M_to_XL) {
    width: var(--grid-10-12);
  }

  @media (--XL_and_up) {
    width: var(--grid-6-12);
  }
}

.items.fullWidth {
  width: 100%;
}

.item {
  margin-bottom: 0.1rem;
  padding: 0 var(--grid-gutter);
}

.item.fullWidth {
  padding-left: 0;
  padding-right: 0;
}

.item > h2 > button {
  @media (--up_to_M) {
    padding: var(--space-xs) var(--space-l);
  }

  @media (--M_and_up) {
    padding: var(--space-xs) 0 var(--space-xs) 0;
  }
  cursor: pointer;
}

.trigger {
  align-items: center;
  background: none;
  border: none;
  border-top: 1px solid var(--color-divider-prio2);
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.items:not(.border-b-0):last-child .trigger {
  border-bottom: 1px solid var(--color-divider-prio2);
}

.trigger:focus-visible {
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: calc(-1 * var(--focus-offset));
}

.header {
  @if $manufactum {
    font-size: var(--font-size-copy-small);
    font-weight: var(--font-weight-copy-semibold);
  }

  @if $magazin {
    @media (--up_to_M) {
      font-size: var(--font-size-copy-small);
    }

    @media (--M_and_up) {
      font-size: var(--font-size-headline-prio4);
    }

    font-weight: var(--font-weight-copy-bold);
  }

  color: var(--main-75);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  line-height: 2rem;
  padding: var(--space-s) 0;
  pointer-events: none;
  text-align: left;
}

.trigger > svg {
  color: var(--main-75);
  flex-shrink: 0;
  height: 1.9rem;
  pointer-events: none;
  transition: transform 0.25s;
  width: 1.9rem;
}

.trigger[aria-expanded="true"] > svg {
  transform: rotate(-180deg);
}

.content {
  @media (--up_to_M) {
    padding: 0 var(--space-l) var(--space-l);
  }

  @media (--M_and_up) {
    padding: 0 0 var(--space-l);
  }
}

.content[hidden] {
  display: none;
}

.content[hidden].preview {
  display: block;
  height: 160px;
  margin-bottom: var(--space-xxl);
  mask-image: linear-gradient(180deg, #000000 10%, transparent);
}
