.root {
  @media (--up_to_XL) {
    width: 100%;
  }

  @media (--XL_and_up) {
    width: calc(var(--grid-2-12) - var(--grid-gutter) * 2);
  }

  margin: 2.4rem var(--grid-gutter) 0;
}
