.content {
  @media (--up_to_M) {
    margin-bottom: 4rem;
  }

  @media (--M_and_up) {
    margin-bottom: 8rem;
  }
}

.upperButtonGroup {
  @media (--up_to_M) {
    margin-top: 0;
  }

  @media (--M_and_up) {
    margin-top: -5.5rem;
  }
}
