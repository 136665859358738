.root {
  background: none;
  border: 1px solid var(--grey-75);
  color: var(--black-100);
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-size: inherit;
  height: 3.8rem;
  line-height: 2.45;
  padding: 0 var(--space-l);
  position: relative;
  width: 100%;
}

.root svg {
  color: var(--grey-200);
  position: absolute;
  right: 1.2rem;
  top: 1rem;
  width: 1.5rem;
}
