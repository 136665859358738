.root {
  align-items: center;
  display: flex;
  margin: 1rem 0;
}

.symbol {
  order: 0;
  padding-right: 2rem;
}

.textContainer {
  order: 1;
}

.small {
  height: 3rem;
  width: 3rem;
}

.medium {
  height: 3.5rem;
  width: 3.5rem;
}

.large {
  height: 4rem;
  width: 4rem;
}
