.root {
  display: inline-block;
  line-height: 2.5rem;
  margin: 0.8rem 0 0 0.4rem;
  position: relative;
  vertical-align: top;
  width: fit-content;
}

.root.line {
  margin: 0.4rem 0 0 1rem;
}

.root.inline {
  margin-left: 90%;
  top: -5rem;
}

.bubble {
  background-color: var(--main-100);
  border-radius: 0.95rem;
  display: inline-block;
  height: 1.9rem;
  left: 0;
  position: absolute;
  width: 1.9rem;
}

.bubbleText {
  /*  
  background: none;
  border-radius: 0.95rem;
  height: 1.9rem;
  left: 0;
  position: absolute;
  */

  margin-top: -0.4rem;
  text-align: center;

  /*
  top: 0;
  width: 1.9rem;
  */
}

.bubble.invisible {
  opacity: 0;
}
