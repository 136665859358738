.root {
  cursor: pointer;
}

.cell {
  background-color: var(--grey-50);
  border-bottom: thin solid silver;
  padding: var(--space-m);
  vertical-align: top;
}

.billsList {
  list-style: none;
  margin: 0;
}

.billsListItem {
  margin: 0;
  padding: 0;
}

.list {
  display: block;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nowrap {
  white-space: nowrap;
}

.drawerToggleCell {
  text-align: left;
}

.orderCreationDateCell {
  text-align: right;
}

.shippingAddressCell {
  hyphens: auto;
}

.totalPriceCell {
  text-align: right;
}

.detailLinkCell {
  text-align: left;
}

.billsCell {
  text-align: left;
}

@media (--up_to_S) {
  .root {
    display: flex;
    flex-wrap: wrap;
  }

  .cell {
    text-align: left;
    width: 100%;
  }

  .orderCreationDateCell {
    order: 1;
  }

  .totalPriceCell {
    order: 2;
  }

  .shippingAddressCell {
    order: 3;
  }

  .detailLinkCell {
    order: 4;
  }

  .drawerToggleCell {
    display: flex;
    justify-content: center;
    order: 5;
    width: 100%;
  }
}

@media (--S_to_L) {
  .root {
    display: flex;
    flex-wrap: wrap;
  }

  .cell {
    text-align: left;
    width: 50%;
  }

  .orderCreationDateCell {
    order: 1;
  }

  .totalPriceCell {
    order: 2;
  }

  .shippingAddressCell {
    order: 3;
  }

  .detailLinkCell {
    order: 4;
  }

  .drawerToggleCell {
    display: flex;
    justify-content: center;
    order: 5;
    width: 100%;
  }
}

@media (--L_and_up) {
  .a11yLabelHelper {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute;
  }

  .drawerToggleCell {
    padding-right: 0;
    vertical-align: middle;
    width: 1%;
  }
}

.smallLabel {
  color: var(--grey-200);
}
