.audio {
  display: flex;
}

.icon {
  color: var(--main-100);
  margin-right: var(--space-l);
}

.text {
  margin-top: var(--space-xs);
}

.download {
  flex-basis: 40%;
  min-width: 16rem;
}
