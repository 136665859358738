.main {
  border-bottom: 1px solid var(--color-divider-prio2);
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--space-xxl);
  max-width: 100%;
  padding: 0 var(--grid-outer-space) var(--space-xxl);
  position: relative;
  width: 100%;
}

:global(.render-app) .main {
  padding-top: var(--grid-outer-space);
}

.message {
  @media (--up_to_L) {
    margin-top: calc(-1 * var(--space-s));
  }

  @media (--L_and_up) {
    margin-top: calc(-1 * var(--space-xl));
  }
}

:global(.render-app) .message {
  margin-top: 0;
}

.imageColumn {
  @media (--up_to_L) {
    flex-basis: var(--grid-12-12);
    max-width: var(--grid-12-12);
  }

  @media (--L_to_XL) {
    flex-basis: var(--grid-7-12);
    max-width: var(--grid-7-12);
  }

  @media (min-width: 840px) and (max-width: 999px) {
    flex-basis: var(--grid-8-12);
    max-width: var(--grid-8-12);
  }

  @media (--XL_to_XXL) {
    flex-basis: var(--grid-8-12);
    max-width: var(--grid-8-12);
  }

  @media (--XXL_and_up) {
    flex-basis: var(--grid-9-12);
    max-width: var(--grid-9-12);
  }
}

.imageContainer.withoutThumbnails {
  @media (max-width: 767px) {
    height: 350px;
    margin: 0 0 2rem 0;
  }

  @media (min-width: 768px) and (max-width: 811px) {
    height: 350px;
    margin: 0 2rem 2rem 0;
  }

  @media (min-width: 812px) {
    height: 700px;
    margin: 0 3rem 0 0;
  }

  position: relative;
}

.imageContainer.thumbnails {
  @media (max-width: 767px) {
    height: 350px;
    margin: 0 0 2rem 0;
  }

  @media (min-width: 768px) and (max-width: 811px) {
    height: 350px;
    margin: 0 2rem 2rem 0;
  }

  @media (min-width: 812px) {
    height: 700px;
    margin: 0 3rem 0 0;
  }

  position: relative;
}

.imageContainer.clothing {
  @media (--up_to_M) {
    height: 60vh;
    max-height: 1200px;
  }
}

.flags {
  @media (--up_to_L) {
    left: 1rem;
    margin-left: 0;
  }

  @media (--L_to_XXL) {
    left: 2.5rem;
    margin-left: 0;
  }

  @media (--XXL_to_XXXL) {
    left: 10.74rem;
    margin-left: 26px;
  }

  @media (--XXXL_and_up) {
    left: 10%;
    margin-left: 26px;
  }

  display: flex;
  flex-direction: column;
  position: absolute;
  text-align: center;
  top: 0;
  z-index: 5;
}

.flags .flag {
  margin-bottom: var(--space-xs);
  text-align: left;
}

.formColumn {
  @media (--up_to_M) {
    flex-basis: var(--grid-12-12);
    max-width: var(--grid-12-12);
  }

  @media (--M_to_L) {
    flex-basis: var(--grid-8-12);
    margin: 0 auto;
    max-width: var(--grid-8-12);
  }

  @media (--L_to_XL) {
    flex-basis: var(--grid-5-12);
    max-width: var(--grid-5-12);
  }

  @media (min-width: 840px) and (max-width: 999px) {
    flex-basis: var(--grid-4-12);
    max-width: var(--grid-4-12);
  }

  @media (--XL_to_XXL) {
    flex-basis: var(--grid-4-12);
    max-width: var(--grid-4-12);
  }

  @media (--XXL_and_up) {
    flex-basis: var(--grid-3-12);
    max-width: var(--grid-3-12);
  }

  padding: 0 var(--grid-gutter);
}

.productInfo {
  margin: 0 var(--space-l) var(--space-xxl);
}
