.gutter {
  margin: 0 var(--grid-gutter);
}

.formfield-newsletter {
  /*! keep */
}

.formfield-newsletter > label.formfield-newsletter-label {
  @media (--up_to_L) {
    max-height: calc(3 * var(--line-height-copy-normal));
  }

  @media (--L_and_up) {
    max-height: calc(4 * var(--line-height-copy-normal));
  }
}
