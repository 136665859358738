.root {
  @media (--up_to_XL) {
    width: 100%;
  }

  @media (--XL_and_up) {
    width: var(--grid-9-12);
  }

  margin: var(--space-m) 0 5.5rem;
}

.loadingSpinnerContainer {
  padding: 5.5rem 0;
  width: 100%;
}
