.root {
  display: flex;
}

.label {
  flex-basis: 13rem;
  line-height: 3.8rem;
  margin-right: var(--space-m);
}

.label.hideLabelOnSmallScreen {
  @media (--up_to_M) {
    display: none;
  }
}

.selectWrapper {
  flex: 1;
}
