@media (--L_and_up) {
  .root {
    border-collapse: collapse;
    margin-bottom: var(--space-s);
    width: 100%;
  }
  .root th {
    text-align: left;
  }
  .root thead th {
    border-bottom: 3px solid var(--grey-50);
  }

  .root th,
  .root td {
    padding: 0.5rem 1.5rem;
  }

  .root .right {
    text-align: right;
  }

  .root .center {
    text-align: center;
  }

  .root tr:nth-child(odd) td {
    background-color: var(--grey-35);
  }

  .root td:empty::before {
    content: "-";
  }

  .root .break {
    display: none;
  }
}
@media (--up_to_L) {
  .root {
    border-collapse: collapse;
    display: flex;
    margin-bottom: var(--space-s);
    width: 100%;
  }
  .root thead {
    display: none;
  }

  .root tbody {
    width: 100%;
  }

  .root tr {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0.5rem 0;
    width: 100%;
  }

  .root .grow {
    flex-grow: 0;
  }

  .root .break {
    flex-basis: 100%;
    height: 0;
    padding: 0;
  }

  .root tr:nth-child(odd) {
    background-color: var(--grey-35);
  }

  .root th {
    text-align: left;
  }
  .root thead th {
    border-bottom: 3px solid var(--grey-50);
  }

  .root th,
  .root td {
    display: flex;
    flex: 0 1 auto;
    padding: 0 1rem;
    white-space: nowrap;
  }
  .root td:empty {
    display: none;
  }

  .root .right {
    text-align: right;
  }

  .root .center {
    text-align: center;
  }

  .root tr:nth-child(odd) td {
    background-color: var(--grey-35);
  }
}
