.textCell {
  padding: var(--space-m);
  text-align: left;
}

.numberCell {
  padding: var(--space-m);
  text-align: right;
}

.visuallyHidden {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute;
}

@media (--up_to_L) {
  .root {
    display: block;
  }

  .root thead {
    display: none;
  }
}

@media (--L_and_up) {
  .root {
    border-collapse: collapse;
    width: 100%;
  }
}
