.root {
  overflow-x: hidden;
}

.manufacturer {
  color: var(--color-copy-small);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.name {
  @media (--up_to_M) {
    max-height: calc(var(--line-height-copy-normal) * 2);
    position: relative;
  }

  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

@media (--up_to_M) {
  .name::after {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 60%
    );
    bottom: 0;
    content: "";
    height: var(--line-height-copy-normal);
    position: absolute;
    right: 0;
    text-align: right;
    top: var(--line-height-copy-normal);
    width: 30px;
  }
}

.skeleton .name::after {
  display: none;
}
