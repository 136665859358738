.selections {
  background-color: var(--grey-35);
  margin-bottom: var(--space-m);
  padding: var(--space-l) var(--space-l) 0 var(--space-l);
}

.freeInputWrapper {
  padding-top: var(--space-xs);
}

.additionalInfo {
  padding-bottom: var(--space-s);
}

.addToBasket {
  margin-top: var(--space-l);
}

.addToNotepad {
  margin-top: var(--space-l);
}

.goGreenInfo {
  text-align: center;
}

.basketButtonIcon {
  @if $magazin {
    display: none;
  }
}
