.gutter {
  margin: 0 var(--grid-gutter);
}

.content {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4rem;
}

.overview {
  /* width: 75%; */
  width: 100%;
}

.logOff {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: var(--space-m) 0;
  width: 100%;
}

.logOffDescription {
  @media (--up_to_XL) {
    width: 100%;
  }

  @media (--XL_and_up) {
    width: var(--grid-7-12);
  }

  @media (--L_and_up) {
    padding-right: var(--space-xs);
  }

  display: inline-block;
}

.logOffButton {
  @media (--up_to_L) {
    width: 350px;
  }

  @media (--L_to_XL) {
    width: 42.5%;
  }

  @media (--XL_and_up) {
    width: 28%;
  }

  @media (--L_and_up) {
    margin-left: var(--space-xs);
  }

  display: inline-block;
  text-align: right;
}

.singleColMaxWidth {
  max-width: 38em;
}
