.root {
  border-top: thin solid var(--grey-75);
  color: var(--color-copy-light);
  display: flex;
  flex-wrap: wrap;
  font-size: var(--font-size-copy-light);
  font-weight: var(--font-weight-copy-light);
  line-height: var(--line-height-copy-light);
  margin: 0 0 var(--space-m) 0;
}

.root:first-child {
  border-top: 0 none;
}

.root button {
  background: transparent;
  border: 0 none;
  color: inherit;
  font: inherit;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.root button:hover {
  text-decoration: underline;
}

.colQuantity,
.colDescription {
  @media (--up_to_L) {
    padding: var(--space-s);
  }

  @media (--L_and_up) {
    padding: var(--space-l);
  }
}

.colQuantity {
  @media (--up_to_M) {
    align-items: center;
    justify-content: flex-start;
    padding: 0 0 0 var(--space-s);
    width: var(--grid-6-12);
  }

  @media (--M_to_L) {
    justify-content: flex-start;
    padding: 0 0 0 var(--space-s);
    width: var(--grid-6-12);
  }

  @media (--L_to_XL) {
    justify-content: flex-end;
    width: var(--grid-3-12);
  }

  @media (--XL_and_up) {
    justify-content: flex-end;
    width: var(--grid-2-12);
  }
  display: flex;
}

.colDescription {
  @media (--up_to_L) {
    width: var(--grid-12-12);
  }

  @media (--L_to_XL) {
    width: var(--grid-7-12);
  }

  @media (--XL_and_up) {
    width: var(--grid-8-12);
  }

  display: flex;
}

.colSum {
  @media (--up_to_L) {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 0 var(--space-s) 0 0;
    width: var(--grid-6-12);
  }

  @media (--L_and_up) {
    padding: var(--space-xl) 0 var(--space-xl) var(--grid-gutter);
    width: var(--grid-2-12);
  }

  color: var(--black-100);
  font-size: var(--font-size-copy-normal);
  font-weight: var(--font-weight-copy-bold);
  line-height: var(--line-height-copy-normal);
  text-align: right;
  white-space: nowrap;
}

.quantityValue {
  margin-left: var(--space-s);
}

.articleName {
  color: var(--black-100);
  font-size: var(--font-size-copy-normal);
  font-weight: var(--font-weight-copy-em);
  line-height: var(--line-height-copy-strong);
}

.description {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.product {
  vertical-align: top;
}

.descriptionPt1,
.descriptionPt2 {
  @media (--up_to_XL) {
    width: 100%;
  }

  @media (--XL_and_up) {
    width: 50%;
  }

  vertical-align: top;
}

.descriptionPt2 {
  @media (--up_to_L) {
    margin-top: var(--space-xs);
  }

  @media (--L_to_XL) {
    padding-left: 130px;
  }

  @media (--XL_and_up) {
    padding-left: var(--grid-gutter);
  }

  display: inline-block;
}

.descriptionPt1 > div {
  display: flex;
}

.descriptionPt2 > div {
  @media (--up_to_L) {
    margin-top: var(--space-xs);
  }

  @media (--L_to_XL) {
    margin: var(--space-l) 0 var(--space-l) 0;
  }
}

.descriptionButtons {
  @media (--up_to_L) {
    display: flex;
  }
  @media (--L_and_up) {
    margin-top: var(--space-l);
  }
}

.image {
  height: auto;
  margin-right: calc(2 * var(--grid-gutter));
  width: calc(100% - var(--grid-gutter));
}

.color,
.size {
  display: table-row;
}

.key {
  display: table-cell;
  padding-right: var(--space-m);
  vertical-align: baseline;
}

.val {
  color: var(--black-100);
  display: table-cell;
  vertical-align: baseline;
}

.manufacturer {
  text-transform: uppercase;
}

.ageRelated .icon {
  display: inline-block;
  height: 2.8rem;
  margin: 0 0.5rem 0.5rem 0;
  vertical-align: middle;
  width: 2.8rem;
}

.root .ageRelated button {
  margin-right: var(--space-s);
  text-decoration: underline;
}

.ageRelatedDrawerClosed {
  display: none;
}

.ageRelatedDrawerOpen {
  display: block;
}

.importantNote {
  color: var(--error-100);
  display: block;
  font-size: var(--font-size-copy-small);
  font-weight: var(--font-weight-copy-small);
  grid-column: span 2;
  line-height: var(--line-height-copy-small);
}

.mergeInfo {
  @media (--L_and_up) {
    margin-bottom: 0;
    margin-left: 100px;
  }

  @media (--up_to_L) {
    margin-bottom: var(--grid-gutter);
    margin-left: 0;
  }

  margin-top: var(--grid-gutter);
}

.mergeInfo > svg {
  margin-left: var(--grid-gutter);
  margin-right: var(--grid-gutter);
}

.quantityRestriction {
  color: var(--attention-100);
  display: block;
  margin-top: var(--space-m);
}

@media (--up_to_L) {
  .lineItemButtons {
    clear: both;
    display: block;
    text-align: right;
  }

  .lineItemButtons.compact {
    padding: 0;
  }

  .lineItemButtons.compact button {
    margin: calc(-1 * var(--space-s));
    padding: var(--space-s);
  }

  .buttons {
    margin-right: var(--space-l);
  }

  .buttons button {
    margin: 0;
    padding: var(--space-s) 0;
  }

  .descriptionButtons button {
    padding: var(--space-s) 0;
  }

  .deleteButton {
    display: none;
  }
}

@media (--L_and_up) {
  .lineItemButtons {
    display: block;
    padding: 0 0 var(--space-s) 0;
  }

  .lineItemButtons:last-child {
    padding: 0;
  }
}

div.eegReturnToggle {
  align-items: baseline;
  display: flex;
  margin-top: 2.5rem;
}

.eegCheckbox {
  height: var(--space-m);
  min-width: var(--space-m);
  position: relative;
  top: 0.1rem;
}

.eegCheckboxLabel {
  margin-left: var(--space-s);
}

a.eegReturnInfoButton {
  margin-bottom: var(--space-xs);
  margin-left: var(--space-xs);
}

.mobileDeleteButton {
  @media (--up_to_L) {
    display: flex;
    justify-content: flex-end;
  }

  @media (--L_and_up) {
    display: none;
  }
}
