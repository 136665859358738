.addToNotepad {
  margin-top: var(--space-l);
}

.wishlistButtonIcon {
  @if $manufactum {
    color: var(--main-100);
  }
  @if $magazin {
    display: none;
  }
}
