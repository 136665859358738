.root button {
  color: var(--color-copy-light);
  font-size: var(--font-size-copy-light);
  font-weight: var(--font-weight-copy-light);
  line-height: var(--line-height-copy-light);
}

.variantCell select {
  width: auto;
}

.variantInputLabel {
  white-space: normal;
}

.error {
  background-color: var(--error-50);
  color: var(--error-100);
  display: block;
  padding: 0.5rem 1rem;
}

.errorMessage {
  color: var(--error-100);
  margin-top: 0.25em;
}

.quantityInput,
.quantityLabel {
  vertical-align: baseline;
}

@media (--up_to_L) {
  .root {
    align-content: flex-end;
    border-top: thin solid var(--grey-75);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: var(--space-xxl);
    padding-top: var(--space-xl);
  }

  .articleNumberCell {
    margin-bottom: var(--space-s);
    order: 1;
    width: 100%;
  }

  .articleNumberCell input,
  .variantCell input {
    padding-right: 1.5rem;

    /* width: auto; */
  }

  .productCell {
    margin-bottom: var(--space-s);
    order: 2;
    width: 100%;
  }

  .quantity {
    margin: 0 0 var(--space-l) 0;
  }

  .quantityCell {
    order: 4;
    width: 45%;
  }

  .quantityCell input {
    width: 50%;
  }

  .variantCell {
    margin-bottom: var(--space-s);
    order: 3;
    width: 100%;
  }

  .deleteCell {
    align-self: flex-end;
    order: 5;
    padding-bottom: var(--space-s);
    width: 45%;
  }

  .quantityLabel,
  .labelArticleNumber,
  .variantInputLabel {
    display: block;
    margin-bottom: var(--space-xs);
  }
}

@media (--L_and_up) {
  .root {
    border-top: thin solid var(--grey-75);
  }

  .root td {
    padding: var(--space-m);
    vertical-align: top;
  }

  .root td:first-child {
    padding-left: 0;
  }

  .root td:last-child {
    padding-right: 0;
  }

  .number {
    text-align: right;
  }

  .quantity {
    margin-bottom: 0.5em;
  }

  .variantCell div {
    display: inline-block;
  }

  .variantCell label {
    margin-right: var(--space-xs);
  }

  .root .quantityInput,
  .root .variantInput {
    width: auto;
  }

  .root .deleteCell {
    padding-top: var(--space-l);
  }

  .labelArticleNumber {
    display: none;
  }

  .articleNumberCell {
    width: var(--grid-2-12);
  }

  .articleDescriptionCell {
    width: var(--grid-6-12);
  }

  .quantityCell,
  .variantCell {
    width: var(--grid-2-12);
  }
}
