.drawer {
  background: var(--white-100);
  background: linear-gradient(to bottom, #eeeeee 0%, var(--white-100) 0.75rem);
  border-left: thin solid var(--grey-50);
  border-right: thin solid var(--grey-50);
  display: none;
  max-height: 0;
  overflow: hidden;
  padding: 0 var(--space-s);
}

.drawer.open {
  border-bottom: thin solid var(--grey-50);
  border-top: thin solid var(--grey-50);
  display: block;
  max-height: 200em;
  padding: var(--space-l) var(--space-s) var(--space-l);
}

:global(.mf-noscript) .drawer,
:global(.mf-noscript) .drawer.open {
  animation: avoidFlash;
  animation-duration: 1s;
  display: block;
  max-height: 200em;
  padding: var(--space-l) var(--space-s) var(--space-l);
}

@keyframes avoidFlash {
  from {
    max-height: 0;
    padding: 0;
  }

  to {
    max-height: 0;
    padding: 0;
  }
}

.itemList {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.item {
  border-bottom: thin solid var(--color-divider-prio2);
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 var(--space-m) 0;
  padding: 0 0 var(--space-xl) 0;
}

.promotion {
  align-items: center;
  display: flex;
}

.promotion > img {
  margin: 1rem 2rem 1rem 1rem;
  width: 100px;
}

.key {
  color: var(--grey-100);
}

.val {
  color: var(--black-100);
}

.article {
  @media (--up_to_L) {
    flex: 100%;
    margin-bottom: var(--space-xs);
    padding-bottom: var(--space-xs);
  }

  @media (--L_and_up) {
    flex: calc(100% * 8 / 12);
  }
}

.additionalFields {
  @media (--up_to_M) {
    width: 100%;
  }

  @media (--L_and_up) {
    margin-left: calc(120px + 1rem);
  }

  display: flex;
  flex-flow: column wrap;
  margin-top: var(--space-m);
}

.parcelTracking,
.ratingsButton {
  @media (--up_to_S) {
    width: 100%;
  }
  @media (--M_and_up) {
    width: 21rem;
  }
  margin-bottom: var(--space-s);
}

.quantity {
  @media (--up_to_L) {
    flex: 50%;
  }

  @media (--L_and_up) {
    flex: calc(100% * 2 / 12);
  }

  font-size: var(--font-size-copy-normal);
  font-weight: var(--font-weight-copy-normal);
  line-height: var(--line-height-copy-normal);
}

.totalPrice {
  @media (--up_to_L) {
    flex: 50%;
    padding-left: 0;
  }

  @media (--L_and_up) {
    flex: calc(100% * 2 / 12);
    padding-left: var(--space-s);
  }

  text-align: right;
}

.item:last-child {
  border-bottom: 0 none;
}

@media (--up_to_L) {
  .root {
    display: block;
  }
}
