.root {
  position: relative;
}

.root h1 {
  @media (--L_and_up) {
    margin-bottom: -6rem;
  }
}

.basketTable {
  border-top: thin solid silver;
  display: block;
  margin-top: var(--space-xl);
}

.basketTable tbody,
.basketTable thead {
  display: block;
}

.head {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute;
}

.sums {
  @media (--up_to_M) {
    padding-left: 0;
  }

  @media (--M_to_L) {
    padding-left: var(--grid-4-12);
  }

  @media (--L_to_XL) {
    padding-left: var(--grid-6-12);
  }

  @media (--XL_to_XXL) {
    padding-left: var(--grid-7-12);
  }

  @media (--XXL_and_up) {
    padding-left: var(--grid-8-12);
  }

  font-size: var(--font-size-copy-normal);
}

.sums th {
  font-weight: normal;
  text-align: left;
}

.sums tr {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.sums td,
.sums th {
  display: inline-block;
  padding: var(--space-s) var(--grid-gutter);
}

.sums .grandTotalRow > * {
  padding-bottom: 0;
}

.sums .advice {
  padding-top: 0;
}

.totalData {
  color: var(--black-100);
  font-weight: var(--font-weight-copy-strong);
  text-align: right;
  white-space: nowrap;
}

.grandTotalData {
  text-align: right;
}

.grandTotalRow {
  align-items: flex-end;
  border-top: thin solid grey;
  margin-top: 1.5rem;
}

.voucherTbody {
  border-top: thin solid var(--color-divider-prio1);
  display: block;
  font-size: var(--font-size-copy-normal);
  padding: var(--space-m) 0;
}

.voucherTbody tr {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.voucherTbody td,
.voucherTbody th {
  display: inline-block;
}

.voucherData,
.voucherHeader {
  @media (--up_to_M) {
    width: 100%;
  }

  @media (--M_to_L) {
    width: var(--grid-8-12);
  }

  @media (--L_to_XL) {
    width: var(--grid-6-12);
  }

  @media (--XL_to_XXL) {
    width: var(--grid-5-12);
  }

  @media (--XXL_and_up) {
    width: var(--grid-4-12);
  }

  text-align: left;
}

.voucherHeader {
  background-color: var(--grey-50);
  color: var(--darkGrey-105);
  font-weight: normal;
  padding: var(--space-s) var(--space-m);
}

.voucherSpace {
  @media (--up_to_M) {
    width: 0;
  }

  @media (--M_to_L) {
    width: var(--grid-4-12);
  }

  @media (--L_to_XL) {
    width: var(--grid-6-12);
  }

  @media (--XL_to_XXL) {
    width: var(--grid-7-12);
  }

  @media (--XXL_and_up) {
    width: var(--grid-8-12);
  }
}

.summaryTable {
  @media (--up_to_M) {
    padding-left: 0;
  }

  @media (--M_to_L) {
    padding-left: var(--grid-4-12);
  }

  @media (--L_to_XL) {
    padding-left: var(--grid-6-12);
  }

  @media (--XL_to_XXL) {
    padding-left: var(--grid-7-12);
  }

  @media (--XXL_and_up) {
    padding-left: var(--grid-8-12);
  }

  display: flex;
  justify-content: flex-end;
}
