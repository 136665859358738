.wrapper[aria-hidden="true"] {
  transform: translateY(100vh);
}

.wrapper[aria-hidden="false"] {
  @media (--up_to_M) {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }

  @media (--M_and_up) {
    bottom: 0;
    right: 0;
  }

  position: fixed;
  transition: transform 0.4s ease-out;
  z-index: 99;
}

.content {
  @media (--up_to_M) {
    height: 100%;
    width: 100%;
  }

  @media (--M_and_up) {
    border-radius: 1.5rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 30px 1px;
    height: 650px;
    margin: 4rem;
    width: 364px;
  }

  animation-duration: 400ms;
  animation-name: slideUpAndFade;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  background-color: var(--white-100);
  display: none;
  flex-direction: column;
}

.advisorVisible {
  display: flex;
}

.header {
  align-items: center;
  border-bottom: 1px solid var(--grey-50);
  display: flex;
  justify-content: space-between;
  padding: var(--space-m);
}

.header .heading.heading {
  margin: 0;
}

.headerButton {
  background: none;
  border: 0 none;
  color: var(--black-100);
  cursor: pointer;
  margin: -1.2rem;
  padding: 1.2rem;
}

.headerButtonNew {
  color: var(--white-100);
}

.headerButton:focus-visible,
.headerButtonNew:focus-visible {
  background-color: var(--focus-background);
  border-radius: var(--focus-border-radius);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: calc(-1 * var(--focus-width));
}

.tooltipContent {
  background-color: var(--white-100);
  border-radius: 4px;
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  padding: 10px 15px;
  user-select: none;
}

.tooltipArrow {
  fill: var(--white-100);
}

.spinner {
  align-self: center;
  width: 5rem;
}

.elements {
  background-color: var(--grey-35);
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: var(--space-s) var(--space-l);
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
