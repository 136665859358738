.small {
  @media (--up_to_L) {
    display: block;
  }

  @media (--L_and_up) {
    display: none;
  }
}

.large {
  @media (--up_to_L) {
    display: none;
  }

  @media (--L_and_up) {
    display: block;
    padding-right: var(--space-xl);
  }
}
