.modalOverride section {
  @media (--up_to_M) {
    width: 100%;
  }
  min-height: unset;
}

.banktransferTextsContainer {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: fit-content;
}

.qrCodeContainer {
  @media (--up_to_M) {
    flex-direction: column;
  }
  @media (--M_and_up) {
    flex-direction: row;
  }
  align-items: center;
  display: flex;
  gap: var(--space-l);
  margin-bottom: var(--space-l);
}

.qrCode {
  @media (--up_to_M) {
    width: 60%;
  }
  @media (--M_and_up) {
    width: 150px;
  }
}
