.formCellOverride div div {
  align-items: center;
  display: flex !important;
}

.formCellOverride div div button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 0.5rem;
  padding: 0.5rem;
}

.modalOverride section {
  @media (--up_to_M) {
    width: 100%;
  }
  min-height: unset;
}

.faqBtn {
  color: var(--black-100);
}

.faqBtn:focus-visible {
  color: var(--focus-text-color);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: calc(-1 * var(--focus-width));
}
