.root {
  display: inline-block;
  white-space: nowrap;
}

.root,
.root a {
  color: var(--grey-150);
  font-size: var(--font-size-copy-strong);
  font-weight: var(--font-weight-copy-strong);
  line-height: var(--line-height-copy-strong);
}

.root a:hover {
  color: var(--main-100);
}

.text {
  margin: 0 var(--focus-width);
}

.text.skeleton {
  background-color: var(--grey-75);
  color: transparent !important;
  height: auto;
  line-height: 1em;
  overflow: hidden;
  vertical-align: baseline;
}

.prev,
.next {
  padding: 1rem;
  text-decoration: none;
}

.prev {
  padding-right: 2rem;
}

.next {
  padding-left: 2rem;
}

.buttonText {
  @media (--up_to_M) {
    display: none;
  }
}

.icon {
  display: inline-block;
  padding: 0 1rem;
  vertical-align: baseline;
}

.element {
  padding: 1rem;
}

.element.active {
  color: var(--main-100);
}

.element.dots {
  @media (--up_to_M) {
    padding: 0;
  }
}

.element.link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.visuallyHidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
