.root input {
  width: 6em;
}

.root button {
  margin: var(--space-xs) 0 0 0;
}

.root label {
  margin-right: 1rem;
}

.errorMessage {
  color: var(--error-100);
  margin-top: 0.25em;
}

.hidden {
  left: -999em;
  position: absolute;
}

.nowrap {
  display: inline-block;
  text-align: right;
  white-space: nowrap;
}

:global(.mf-noscript) .hidden {
  animation: avoidFlash;
  animation-delay: 2s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
  width: 0;
}

@keyframes avoidFlash {
  from {
    opacity: 0;
    width: 0;
  }

  to {
    opacity: 1;
    position: initial;
    text-align: right;
    width: 100%;
  }
}
