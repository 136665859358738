.filters {
  @media (--up_to_L) {
    box-shadow: -20px 0 7px -20px rgba(0, 0, 0, 0.1) inset;
    width: var(--grid-9-12);
  }

  max-height: 150px;

  /* -webkit-overflow-scrolling: touch; */

  /* -ms-overflow-style: -ms-autohiding-scrollbar; */

  overflow-y: auto;
  width: 100%;
}

.checkbox {
  display: inline-block;
  max-width: 15%;
  vertical-align: middle;
}

.label {
  display: inline-block;
  margin-left: calc(var(--space-m) - 0.5rem);
  max-width: 80%;
  vertical-align: top;
}

.label.disabled {
  opacity: 0.5;
}

.filter {
  margin-bottom: 1rem;
}

@if $magazin {
  .filter:nth-child(1) {
    margin-top: 0.4rem;
  }
}

.filter label {
  display: block;
}

.filter label:not(.disabled) {
  cursor: pointer;
}

.filter label:not(.disabled):hover small {
  color: var(--main-100);
  text-decoration: underline;
}

.activeFilter small {
  color: var(--main-100);
  text-decoration: underline;
}

.filter.activeFilter label:not(.disabled):hover small {
  color: var(--black-100);
}
