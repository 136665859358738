.noscript,
:global(.mf-noscript) .content {
  display: none;
}

:global(.mf-noscript) .noscript {
  animation: avoidFlash;
  animation-delay: 2s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  display: block;
  opacity: 0;
}

@keyframes avoidFlash {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
