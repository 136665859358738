@keyframes fast-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 0;
  }
}

:global(.mf-noscript) .submitButtonContainer {
  animation-duration: 3.5s;
  animation-name: fast-fade-in;
  display: block;
  opacity: 1;
}

.root {
  @media (--up_to_L) {
    margin: var(--space-m) 0;
  }

  @media (--L_and_up) {
    padding-right: var(--space-xl);
  }

  scrollbar-width: none; /* fix for overflow scrolling in FF */
}

.submitButtonContainer {
  @media (--L_and_up) {
    display: none;
  }

  margin-top: var(--space-xl);
}

.filters {
  @media (--up_to_L) {
    display: block;
  }

  @media (--L_and_up) {
    display: none;
  }
}

.filters.initialDisplay {
  @media (--up_to_L) {
    display: none;
  }

  @media (--L_and_up) {
    display: block;
  }
}

.filters.initialDisplay.visible {
  margin: 0 0 5rem 0;
}

.heading {
  @media (--up_to_L) {
    margin-bottom: var(--space-m);
  }

  width: 100%;
}

.heading span {
  @media (--L_and_up) {
    color: var(--grey-150);
  }
}

.heading svg {
  color: var(--darkGrey-105);
}

.heading > button {
  width: 100%;
}

.heading.small {
  display: none;
}

.heading.small.open {
  @media (--up_to_L) {
    display: block;
  }
}

.heading.large {
  @media (--up_to_L) {
    display: none;
  }

  @media (--L_and_up) {
    display: block;
  }
}

.heading.large.closed {
  border-bottom: thin solid var(--color-divider-prio2);
  margin-bottom: calc(var(--space-xl) - 1.2rem);
}

.resetButtonContainer {
  border-bottom: 1px solid var(--color-divider-prio2);
  margin-bottom: calc(var(--space-xxl) - 1.2rem);
  margin-top: calc(var(--space-xl) - 0.8rem);
  opacity: 0;
  padding-bottom: calc(var(--space-xxl) - 2rem);
}

.resetButtonContainer.visible {
  opacity: 1;
}
