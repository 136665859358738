.root {
  margin-bottom: var(--space-xl);
}

.table {
  border-collapse: collapse;
  margin-top: 3rem;
}

.name {
  border-top: 1px solid var(--black-100);
  padding: 1rem 4rem 1rem 0;
}

.value {
  border-top: 1px solid var(--black-100);
  padding: 1rem 0;
}
