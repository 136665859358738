.container {
  @media (--XL_and_up) {
    display: flex;
  }
  @media (--up_to_XL) {
    display: block;
  }
}

.balanceContainer {
  @media (--up_to_L) {
    flex-direction: column;
  }
  align-items: center;
  display: flex;
  margin-bottom: var(--space-xxxl);
}

.balanceButtonContainer {
  @media (--L_and_up) {
    margin-left: 4rem;
    width: fit-content;
  }
  @media (--up_to_L) {
    width: 100%;
  }
}

.content {
  margin-bottom: var(--space-xxl);
  margin-top: var(--space-m);
  max-width: 1000px;
  width: 100%;
}

.tableContainer {
  margin-bottom: var(--space-xxxl);
  margin-top: var(--space-l);
}

.amount.positive {
  color: var(--success-100);
}

.amount.negative {
  color: var(--error-100);
}

.mobile-label {
  @media (--L_and_up) {
    display: none;
  }
  margin-right: var(--space-s);
}

.content .mobile-bold {
  @media (--up_to_L) {
    font-weight: var(--font-weight-copy-em);
  }
}
