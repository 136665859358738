.container {
  @media (--up_to_L) {
    justify-content: space-between;
    margin-bottom: var(--space-m);
    width: 100%;
  }
  @media (--L_and_up) {
    width: fit-content;
  }
  align-items: center;
  background-color: var(--grey-35);
  display: flex;
  padding: var(--space-l);
}

.value {
  @media (--up_to_M) {
    padding-left: var(--space-s);
  }
  @media (--M_and_up) {
    padding-left: var(--space-l);
  }
  white-space: nowrap;
}

.value.positive {
  color: var(--success-100);
}

.value.negative {
  color: var(--error-100);
}
