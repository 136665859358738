.root {
  text-align: center;
}
.loading {
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;
  text-align: center;
}
.loadingMessage {
  margin-top: var(--space-s);
}
.qrCodeImage {
  width: 100%;
}
.byEmailButton {
  margin-top: var(--space-xxl);
}
