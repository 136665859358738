.element {
  display: flex;
  flex-direction: column;
}

.plainText {
  text-align: center;
}

.messageWrapper {
  align-items: flex-end;
  display: flex;
}

.message {
  animation-duration: 400ms;
  animation-name: slideUpAndFade;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  border-radius: 1rem;
  display: flex;
  padding: var(--space-s) var(--space-m);
  position: relative;
  word-break: break-word;
}

.messageWrapper > .message.FROM_BOT {
  border-bottom-left-radius: 0;
  flex: 1;
}

.messageWrapper > .message.FROM_BOT.processingIndicator {
  flex: none;
}

.messageWrapper > .message.FROM_BOT:before,
.message.FROM_USER:before {
  background-color: inherit;
  bottom: 0;
  content: "";
  height: 1.2rem;
  inset-inline-start: -1.2rem;
  mask-image: url("data:image/svg+xml,%3csvg width='3' height='3' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m 0 3 L 3 3 L 3 0 C 3 1 1 3 0 3'/%3e%3c/svg%3e");
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
  position: absolute;
  width: 1.2rem;
}

.messageWrapper > .message.FROM_BOT:before {
  inset-inline-start: -1.2rem;
  mask-image: url("data:image/svg+xml,%3csvg width='3' height='3' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m 0 3 L 3 3 L 3 0 C 3 1 1 3 0 3'/%3e%3c/svg%3e");
}

.message.FROM_USER:before {
  inset-inline-start: 99.9%;
  mask-image: url("data:image/svg+xml,%3csvg width='3' height='3' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m 0 3 L 1 3 L 3 3 C 2 3 0 1 0 0'/%3e%3c/svg%3e");
}

.message.FROM_BOT {
  background-color: var(--white-100);
}

.messageWrapper > .message.FROM_BOT {
  border-bottom-left-radius: 0;
}

.message.FROM_USER {
  background-color: var(--grey-60);
  border-bottom-right-radius: 0;
  margin-left: 4.5rem;
}

.asSuggestion {
  background-color: var(--hint-50);
  border: 0;
  cursor: pointer;
  margin-left: 2rem;
}

.asSuggestion .inputSuggestion {
  color: var(--hint-100);
  text-align: left;
}

.logo {
  margin: 0 1.5rem 0.2rem 0;
  width: 3rem;
}

:global(.mf-ios) .logo {
  height: 3rem;
  margin: 0 1.5rem -0.2rem 0;
}

:global(.mf-ios) .logo > div {
  height: 100%;
}

.retryButton {
  margin: var(--space-m) auto;
}

.processingIndicator {
  background-color: var(--white-100);
  margin-right: auto;
  padding: 2rem 1rem 1rem;
}

.processingIndicator > div {
  animation: bouncing-loader 0.5s infinite alternate;
  background-color: var(--grey-100);
  border-radius: 50%;
  height: 1.2rem;
  margin: 0.4rem 0.3rem;
  opacity: 1;
  width: 1.2rem;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-12px);
  }
}

.processingIndicator > div:nth-child(2) {
  animation-delay: 0.2s;
}

.processingIndicator > div:nth-child(3) {
  animation-delay: 0.4s;
}

.productlist {
  background-color: var(--white-100);
  border-radius: 1rem;
  padding: var(--space-m);
}

.feedback {
  padding-bottom: 1.5rem;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
