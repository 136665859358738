.buttonWrapper {
  @media (--up_to_M) {
    width: 100%;
  }

  @media (--M_to_L) {
    width: calc(50% - var(--grid-gutter));
  }

  @media (--L_to_XL) {
    width: calc(40% - var(--grid-gutter) * 1.2);
  }

  @media (--XL_to_XXL) {
    width: calc(75% - (var(--grid-gutter) / 2));
  }

  @media (--XXL_and_up) {
    width: calc(50% - var(--grid-gutter));
  }
}
