.link {
  @media (--up_to_XXL) {
    margin-bottom: var(--space-l);
  }

  @media (--XXL_and_up) {
    margin-bottom: var(--space-xs);
  }

  color: var(--grey-150);
  display: block;
  font-size: var(--font-size-copy-normal);
  line-height: var(--line-height-copy-normal);
}

a.link {
  text-decoration: none;

  &:hover {
    color: var(--main-100);
    text-decoration: underline;
  }

  &:active {
    color: var(--main-75);
    text-decoration: underline;
  }
}

.link.active {
  color: var(--main-75);
  cursor: pointer;
  text-decoration: underline;
}

.skeleton {
  background-color: var(--grey-50);
  color: transparent !important;
  display: inline-block;
  line-height: 1em;
}
