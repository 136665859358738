.stickybannerRoot {
  @media (max-width: 1199px) and (orientation: portrait) {
    display: none;
  }
  @media (max-width: 1199px) and (orientation: landscape) {
    display: none;
  }
  @media (min-width: 1200px) {
    bottom: 200px;
  }

  pointer-events: none;
  position: fixed;
  right: 0;
  z-index: 98;
}

.stickybannerRoot.wide {
  @media (min-width: 1200px) {
    top: 20%;
  }
}

.stickybannerContainer {
  display: flex;
  pointer-events: none;
}

.arrow {
  left: 12px;
  position: absolute;
  top: 12px;
}

.labelContainer {
  display: flex;
  pointer-events: none;
}

.stickybannerLabelSide {
  @if $manufactum {
    background: #005232;
  }

  @if $magazin {
    background: #333333;
    font-family: var(--font-family-headline) !important;
    text-transform: uppercase;
  }

  border: none;
  box-shadow: 2px 0 10px 2px rgb(0 0 0 / 40%);
  color: white;
  cursor: pointer;
  height: 170px;
  pointer-events: all;
  width: 40px;
}

.labelText {
  @if $manufactum {
    left: 8px;
  }

  @if $magazin {
    font-weight: bold;
    left: 10px;
  }

  font-size: 17px;
  height: 100px;
  position: absolute;
  top: 50px;
  transform: rotate(180deg);
  user-select: none;
  vertical-align: top;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-lr;
  z-index: 15;
}

.buttonXIcon {
  background-color: transparent;
  border: none;
  height: 3rem;
  padding: 0.5rem;
  width: 3rem;
}

.buttonXIcon.open {
  cursor: pointer;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}

.buttonXIcon.close {
  display: none;
}

.stickybannerCloseIcon {
  @if $manufactum {
    fill: #ffffff;
  }

  @if $magazin {
    fill: #333333;
  }
}

.stickybannerLabelSide:focus-visible,
.buttonXIcon:focus-visible {
  background-color: var(--focus-background);
  color: var(--focus-text-color);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: calc(-1 * var(--focus-width));
}

.buttonXIcon:focus-visible .stickybannerCloseIcon {
  fill: var(--focus-text-color);
}

.buttonXIcon:focus-visible {
  border-radius: var(--focus-border-radius);
  box-shadow: 0 0 0 calc(var(--focus-width)) var(--focus-color-inverted);
}

.bannerContent {
  box-shadow: 0 0 10px 2px rgb(0 0 0 / 40%);
  pointer-events: all;
  transition: margin 0.3s ease;
  width: 220px;
}

.bannerContent.wide {
  width: 280px;
}

.bannerContent.open {
  margin-right: 0;
}

.bannerContent.close {
  box-shadow: none;
  margin-right: -220px;
}

.bannerContent.close.wide {
  margin-right: -280px;
}

.bannerImageLink {
  display: flex;
}

.bannerImageLink:focus-visible {
  box-shadow: 0 0 0 calc(2 * var(--focus-width)) var(--focus-color-inverted);
}

.bannerImage {
  background-color: white;
  min-height: 310px;
}

.newsletterWrapper {
  @if $manufactum {
    background-color: var(--main-100);
  }

  @if $magazin {
    background-color: var(--grey-35);
  }

  padding: var(--space-m);
}
