.root svg {
  height: 3rem;
  width: 3.5rem;
}

.root text {
  fill: #ffffff;
  font-size: 26px;
  pointer-events: auto;
  text-align: left;
  text-anchor: start;
  text-transform: uppercase;
}

.ageRelated {
  fill: #c80000;
}
