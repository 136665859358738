.container {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
}

.container-margin {
  @media (--up_to_M) {
    margin-bottom: 1rem;
  }
  @media (--M_and_up) {
    margin-bottom: 5rem;
  }
}

.customerAndVoucher {
  @media (--up_to_XL) {
    width: 100%;
  }

  @media (--XL_and_up) {
    width: calc(var(--grid-3-12) - var(--grid-gutter) * 2);
  }

  background-color: var(--grey-50);
  margin: var(--space-m) var(--grid-gutter) 0;
}

.customer {
  @media (--L_to_XL) {
    display: flex;
    flex-wrap: wrap;
  }

  border-bottom: thin solid white;
  padding: var(--space-m) 8.33333%;
}

.customer > *:first-child {
  @media (--L_to_XL) {
    width: 100%;
  }
}

.gutter {
  border-bottom: thin solid var(--color-divider-prio1);
  margin: 0 var(--grid-gutter);
}

.billingAddress,
.deliveryAddress,
.deliveryMethod,
.paymentMethod {
  @media (--L_to_XL) {
    width: 50%;
  }

  padding-bottom: var(--space-l);
}

.products {
  @media (--XL_and_up) {
    width: var(--grid-9-12);
  }

  @media (--up_to_XL) {
    width: 100%;
  }

  flex-basis: auto;
  margin-top: var(--space-m);
}

.footer {
  background-color: #0b5488;
  height: 20%;
  margin: var(--space-m) 0;
  width: 100%;
}

.basketTable {
  display: block;
}

.basketTable tbody,
.basketTable thead {
  @media (--up_to_M) {
    padding-left: 0;
  }

  @media (--M_and_up) {
    padding: 0 var(--grid-gutter);
  }

  display: block;
}

.basketTableHead {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute;
}

.basketTable tbody.sums {
  border-collapse: collapse;
  display: table;
  width: 100%;
}

.sums th {
  font-weight: normal;
  text-align: right;
  white-space: nowrap;
  width: var(--grid-9-12);
}

.sums td {
  font-weight: normal;
  text-align: right;
  width: var(--grid-3-12);
}

.sums tr {
  display: table-row;
}

.sums td,
.sums th {
  display: table-cell;
  padding: var(--space-s) var(--grid-gutter);
  vertical-align: bottom;
}

.sums td:first-child,
.sums th:first-child {
  padding-left: 0;
}

.sums td:last-child,
.sums th:last-child {
  padding-right: 1rem;
}

.sums .grandTotalRow > * {
  border-top: thin solid grey;
  padding-bottom: 0;
}

.sums .advice {
  padding-top: 0;
}

.totalData {
  color: var(--black-100);
  font-weight: var(--font-weight-copy-em);
  text-align: right;
  white-space: nowrap;
}

.grandTotalData {
  color: var(--grey-75);
  font-size: var(--font-size-headline-prio2);
  line-height: var(--line-height-headline-prio2);
  text-align: right;
}

.sums .totalRow > * {
  border-top: thin solid silver;
}

.deliveryMethod button {
  display: none;
}

:global(.mf-noscript) .deliveryMethod button {
  display: inline-block;
}

.basketDiscountAdvice {
  margin: 3rem 0;
}

.shippingChargeAdvice {
  margin: 3rem 0;
}

.collExpLabel {
  @media (--up_to_L) {
    max-height: calc(3 * var(--line-height-copy-normal));
  }

  @media (--L_and_up) {
    max-height: calc(4 * var(--line-height-copy-normal));
  }
}

.summaryTable {
  @media (--up_to_M) {
    padding-left: var(--grid-gutter);
  }

  @media (--M_to_L) {
    padding-left: calc(var(--grid-4-12) + var(--grid-gutter));
  }

  @media (--L_to_XL) {
    padding-left: calc(var(--grid-6-12) + var(--grid-gutter));
  }

  @media (--XL_to_XXL) {
    padding-left: calc(var(--grid-7-12) + var(--grid-gutter));
  }

  @media (--XXL_and_up) {
    padding-left: calc(var(--grid-8-12) + var(--grid-gutter));
  }

  border-top: thin solid silver;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1.5rem;
  padding-right: var(--grid-gutter);
  padding-top: 1.5rem;
}

.scrollContainer {
  display: block;
}

@media (--XL_and_up) {
  .scrollContainer {
    display: none;
  }
}

.smallButton.smallButton {
  height: 3rem;
  margin-top: 0.6rem;
}

.voucherStyleOverride button span {
  font-size: 1.6rem;
}
