.textblock {
  margin-bottom: var(--space-l);
}

.pdf {
  display: flex;
}

.icon {
  margin-right: var(--space-l);
}

.download {
  flex-basis: 40%;
  min-width: 16rem;
}
