.root {
  border-bottom: 1px solid var(--color-divider-prio2);
  margin-bottom: 3rem;
  padding-bottom: 1rem;
  width: 100%;
}

.root:last-child {
  border-bottom: none;
}

.title {
  @media (--up_to_L) {
    display: none;
  }

  @media (--L_and_up) {
    display: block;
  }

  border-bottom: 0;
  color: var(--grey-150);
  font-size: 1.5rem;
  font-weight: var(--font-weight-copy-bold);
  line-height: 2rem;
  margin-bottom: var(--space-l);
  padding-bottom: 0;
}

.list {
  @media (--up_to_L) {
    display: none;
  }

  @media (--L_and_up) {
    display: block;
  }

  list-style: none;
  margin: 0;
  padding: 0;
}

.skeleton {
  background-color: var(--grey-75);
  color: transparent !important;
  display: inline-block;
  line-height: 1em;
}

.product {
  padding-bottom: 1rem;
  width: 100%;
}

.topic {
  margin-top: 2rem;
  width: 100%;
}
