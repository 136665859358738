.root {
  border-top: 1px solid var(--color-divider-prio2);
  margin-top: var(--space-xl);
  padding-top: var(--space-m);
  text-align: center;
}

.button {
  padding-top: var(--space-m);
}
