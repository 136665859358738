.optionalsList {
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (--up_to_XL) {
  .optionalsList.divider {
    border-bottom: 1px solid var(--color-divider-prio2);
  }
}

.optionalsList:empty {
  display: none;
}

.optionalsListElement {
  @media (--up_to_L) {
    padding-top: var(--space-s);
  }
  @media (--L_and_up) {
    padding-top: var(--space-l);
  }

  border-top: 1px solid var(--color-divider-prio2);
}

.producerList {
  list-style: none;
  margin: 0;
  padding: 0;
}
