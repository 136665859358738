.filterTitle {
  line-height: 2.6rem;
}

.titleContainer {
  display: inline-block;
  font-weight: var(--font-weight-copy-em);
  height: fit-content;
  line-height: 2.4rem;
  margin-top: 0.5rem;
  vertical-align: baseline;
  width: fit-content;
}
