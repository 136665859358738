.root {
  border: 1px solid #999999;
  margin: 14px;
  padding: 4px;
}

.link {
  color: black;
  cursor: pointer;
  text-decoration: none;
}

.link_active {
  color: red;
  cursor: pointer;
  text-decoration: none;
}

.bottom {
  border-bottom: 1px solid var(--color-divider-prio2);
}

.top {
  border-top: 1px solid var(--color-divider-prio2);
}
